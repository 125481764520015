<template>
  <base-section
    id="k-d-a-project-detail-header"
    space="0"
    :height="`${g_bLowerBr ? 'auto' : '90vh'}`"
    :style="{ position: 'relative', overflow: 'hidden', backgroundColor: currentProject.bgColor }"
  >
    <div style="position: absolute; top: 0%; left: 0%; right: 0%; bottom: 0%">
      <icon-project-header-top-left
        style="position: absolute; top: 0%; left: 0%"
        :color="currentProject.bgShapeColor"
      />
      <icon-project-header-middle
        :class="`pattern-middle ${g_bLowerBr && 'mobile'}`"
        :color="currentProject.bgShapeColor"
      />
      <icon-project-header-top-right
        style="position: absolute; top: 0%; right: 0%"
        :color="currentProject.bgShapeColor"
      />
      <icon-project-header-bottom-left
        style="position: absolute; bottom: -1%; left: 0%"
        :color="currentProject.bgShapeColor"
      />
      <icon-project-header-bottom-right
        style="position: absolute; bottom: -1%; right: 0%"
        :color="currentProject.bgShapeColor"
      />
    </div>
    <v-container class="fill-height">
      <v-row>
        <v-col cols="12" style="display: flex; flex-direction: column; align-items: center">
          <div :class="`kda-ts-${g_bLowerBr ? 40 : 64}pt wt-extrabold`" style="align-items: center">
            {{ currentProject.strTitle }}
          </div>
          <div class="line-title" />
        </v-col>
      </v-row>
      <v-row :class="g_bLowerBr ? 'col-reverse px-3' : 'row-wrapper '">
        <v-col cols="12" :lg="`${g_bLowerBr ? 12 : 6}`" :md="`${g_bLowerBr ? 12 : 6}`">
          <div>
            <div class="kda-ts-18pt wt-bold wrap-text">
              <img :src="require(`@/assets/icons/icon-time.png`)" class="icon-image" />
              {{ m_strTime }}
            </div>
            <div class="line-point" />
            <div>{{ currentProject.strProjectDuration }}</div>
          </div>
          <div style="margin-top: 32px">
            <div class="kda-ts-18pt wt-bold wrap-text">
              <img :src="require(`@/assets/icons/icon-worker.png`)" class="icon-image" />
              {{ m_strWorker }}
            </div>
            <div class="line-point" />
            <div>
              <div v-html="currentProject.strProjectMembers" />
            </div>
          </div>
          <div style="margin-top: 32px; margin-bottom: 30px">
            <div class="kda-ts-18pt wt-bold wrap-text">
              <img :src="require(`@/assets/icons/icon-tech.png`)" class="icon-image" />
              {{ m_strTech }}
            </div>
            <div class="line-point" />
            <div
              :class="`wrap-image ${g_bLowerBr && 'mobile'}`"
              :style="{ justifyContent: `${isOddImgTech && g_bLowerBr ? 'center' : 'flex-start'}` }"
            >
              <div
                v-for="(logo, id) in currentProject.arrTechImgs"
                :key="id"
                :class="`${g_bLowerBr ? 'img-container-lower' : 'img-container'}`"
                :style="{
                  maxWidth: `135px`,
                  display: `${g_bLowerBr ? 'flex' : ''}`
                }"
              >
                <base-img
                  :src="require(`@/assets/logos/tech/${logo}`)"
                  class="d-inline-block vertical-align-top mx-1"
                  height="48"
                  width="48"
                  contain
                />
              </div>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          :lg="`${g_bLowerBr ? 12 : 6}`"
          :md="`${g_bLowerBr ? 12 : 6}`"
          style="display: flex; flex-direction: row; justify-content: center"
        >
          <base-img :src="currentProject.imgSrc" max-height="500px" max-width="70%" contain />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDAProjectDetailHeader',

  components: {
    IconProjectHeaderTopLeft: () => import('@/components/base/icons/IconProjectHeaderTopLeft'),
    IconProjectHeaderTopRight: () => import('@/components/base/icons/IconProjectHeaderTopRight'),
    IconProjectHeaderMiddle: () => import('@/components/base/icons/IconProjectHeaderMiddle'),
    IconProjectHeaderBottomLeft: () =>
      import('@/components/base/icons/IconProjectHeaderBottomLeft'),
    IconProjectHeaderBottomRight: () =>
      import('@/components/base/icons/IconProjectHeaderBottomRight')
  },
  mixins: [BaseBreakpoint],

  computed: {
    m_strTime() {
      return this.$vuetify.lang.t('$vuetify.kda.project.time');
    },
    m_strMonths() {
      return this.$vuetify.lang.t('$vuetify.kda.project.months');
    },
    m_strWorker() {
      return this.$vuetify.lang.t('$vuetify.kda.project.worker');
    },
    m_strTech() {
      return this.$vuetify.lang.t('$vuetify.kda.project.tech');
    },
    arrProject() {
      return [
        {
          strType: 'PT. Citra Kreasi Makmur',
          strTitle: 'WAYFINDER',
          strLink: 'wayfinder',
          imgSrc: require(`@/assets/photos/project-detail-4.png`),
          strProjectDuration: `3 ${this.m_strMonths}`,
          strProjectMembers: '2 Front-end<br>1 Back-end<br>1 C++ Engine',
          arrTechImgs: ['logo-polymer.png', 'logo-ts.png', 'logo-cpp.png'],
          bgColor: 'rgba(255, 255, 0, 0.15)',
          bgShapeColor: '#CDCD73'
        },
        {
          strType: 'PT. Asuransi AXA Indonesia',
          strTitle: 'AXA',
          strLink: 'axa',
          imgSrc: require(`@/assets/photos/home-project-2.png`),
          strProjectDuration: `2 Weeks / 14 Days`,
          strProjectMembers: '3 People',
          arrTechImgs: ['logo-unreal.png', 'logo-htc-vive.png'],
          bgColor: 'rgba(0, 0, 143, 0.15)',
          bgShapeColor: '#00008F'
        },
        {
          strType: 'PT. Citra Kreasi Makmur',
          strTitle: 'Load Container',
          strLink: 'load-container',
          imgSrc: require(`@/assets/photos/home-project-3.png`),
          strProjectDuration: `8 ${this.m_strMonths}`,
          strProjectMembers: `2 Unity3D<br>C++ Programmers`,
          arrTechImgs: ['logo-unity.png', 'logo-cpp.png'],
          bgColor: 'rgba(0, 104, 149, 0.15)',
          bgShapeColor: '#006895'
        },
        {
          strType: 'PT. Citra Kreasi Makmur',
          strTitle: 'Real Time Chatbot',
          strLink: 'sqm-chatbot',
          imgSrc: require(`@/assets/photos/home-project-4.png`),
          strProjectDuration: `2 ${this.m_strMonths}`,
          strProjectMembers: `2 Back-end<br>2 Front-end`,
          arrTechImgs: ['logo-django.png', 'logo-react.png', 'logo-mysql.png', 'logo-mongodb.png'],
          bgColor: 'rgba(180, 28, 37, 0.15)',
          bgShapeColor: '#b41c25'
        },
        {
          strType: 'PT. Citra Kreasi Makmur',
          strTitle: 'Price Fetcher',
          strLink: 'price-fetcher',
          imgSrc: require(`@/assets/photos/home-project-5.png`),
          strProjectDuration: `2 ${this.m_strMonths}`,
          strProjectMembers: `2 UI<br>C++ Programmers`,
          arrTechImgs: ['logo-cpp.png', 'logo-qt.png'],
          bgColor: 'rgba(78, 79, 81, 0.15)',
          bgShapeColor: '#4e4f51'
        }
      ];
    },
    m_arrTechnology() {
      const result = [];

      for (let i = 0; i < 4; i++) {
        result.push(require(`@/assets/photos/tech-list-${i + 1}.png`));
      }

      return result;
    },
    currentProject() {
      const currentProject = this.arrProject.filter(
        (item) => this.$route.params.strProject === item.strLink
      )[0];

      return currentProject;
    },
    hexBgColor() {
      return '#CDCD73';
    },
    imgSrcBig() {
      return require(`@/assets/photos/project-detail-4.png`);
    },
    imgSrcSmall() {
      return require(`@/assets/photos/project-detail-5.png`);
    },
    isOddImgTech() {
      return this.m_arrTechnology.length % 2 === 1;
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

.background-tech-list {
  background-color: #fbfbfb;
}

.col-reverse {
  display: flex !important;
  flex-direction: column-reverse !important;
}

.row-wrapper {
  display: flex;
  flex-direction: row;
}

.line-title {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #333333;
  width: 15%;
}

.icon-image {
  height: 50px;
  width: 50px;
  object-fit: contain;
  margin-right: 10px;
}

.wrap-text {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.line-point {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #333333;
  padding-bottom: 5px;
  margin-bottom: 10px;
  width: 50%;
}

.img-container {
  overflow: hidden;
  position: relative;
  height: 100%;
  position: relative;
  align-items: flex-start;
  img {
    &.right {
      height: 460px;
      width: 460px;
    }

    &.top {
      height: fit-content;
      width: 290px;
      position: relative;
    }
  }
}

.img-container-lower {
  position: relative;
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
}

.pattern-middle {
  position: absolute;
  display: flex;
  margin: auto;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;

  &.mobile {
    align-items: center;
    justify-content: flex-end;
    right: -8%;
  }
}

.wrap-image {
  gap: 20px;
  display: flex;
  &.mobile {
    overflow: hidden;
    flex-wrap: wrap;
  }
}
</style>
